export default [
    {
        path: "/report/money",
        name: "report-money",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/report/money"),
    },
    {
        path: "/report/sub/money",
        name: "report-sub-money",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/report/sub-money"),
    },
    {
        path: "/report/settlement/day",
        name: "report-settlement-day",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/report/settlement-day"),
    },
    {
        path: "/report/settlement/month",
        name: "report-settlement-month",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/report/settlement-month"),
    },    {
        path: "/report/settlement/year",
        name: "report-settlement-year",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/report/settlement-year"),
    },
    {
        path: "/report/log/activity",
        name: "report-log-activity",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/report/log-activity"),
    },
    {
        path: "/report/log/transfer",
        name: "report-log-transfer",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/report/log-transfer"),
    },
];
