import APIClient from '@/libraries/api-client'
import qs from 'querystring'
// import store from '../store'

class Statistic extends APIClient {

    async getStatBetDate(beginDate, endDate, params) {
        const variables = qs.stringify(params)
        return await this.get(`statistic/getStatBetDate/${beginDate}/${endDate}?${variables}`, params)
    }

    async getStatBetMonthly(beginDate, endDate) {
        return await this.get(`statistic/getStatBetMonthly/${beginDate}/${endDate}`)
    }

    async getStatBetDateByVendor(beginDate, endDate, params) {
        return await this.post(`statistic/vendor/${beginDate}/${endDate}`, params)
    }

    async getCashYearly(params) {
        return await this.post(`statistic/cash/yearly`, params)
    }

    async getCashMonthly(params) {
        return await this.post(`statistic/cash/monthly`, params)
    }

    async getSubCashYearly(params) {
        return await this.post(`statistic/sub/cash/yearly`, params)
    }

    async getSubCashMonthly(params) {
        return await this.post(`statistic/sub/cash/monthly`, params)
    }
}

export default Statistic
