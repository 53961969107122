export default [
    {
        path: "/transaction/all",
        name: "transaction-all",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/transaction/all"),
    },
    {
        path: "/transaction/my/money",
        name: "transaction-my-money",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/transaction/my-money"),
    },
    {
        path: "/transaction/admin/money",
        name: "transaction-admin-money",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/transaction/admin-money"),
    },
    {
        path: "/transaction/partner/money",
        name: "transaction-partner-money",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/transaction/partner-money"),
    },
    {
        path: "/transaction/user/money",
        name: "transaction-user-money",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/transaction/user-money"),
    },
    {
        path: "/transaction/game",
        name: "transaction-game",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/transaction/game"),
    },
];
