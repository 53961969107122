export default [
    {
        path: "/",
        name: "dashboard",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/dashboards/default"),
    },
];
