export default [
    // stress
    {
        path: "/api/stress",
        name: "api-stress",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/stress"),
    },
    {
        path: "/api/common/introduce",
        name: "api-common-introduce",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/common/introduce"),
    },
    {
        path: "/api/common/vendors",
        name: "api-common-vendors",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/common/vendors"),
    },
    {
        path: "/api/common/games",
        name: "api-common-games",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/common/games"),
    },
    {
        path: "/api/common/partner/balance",
        name: "api-common-partner-balance",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/common/partner-balance"),
    },
    {
        path: "/api/common/transactions",
        name: "api-common-transactions",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/common/transactions"),
    },
    {
        path: "/api/common/detail",
        name: "api-common-detail",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/common/detail"),
    },
    {
        path: "/api/common/detail/callback",
        name: "api-common-detail-callback",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/common/detail-callback"),
    },
    {
        path: "/api/common/transaction/pragmatic",
        name: "api-common-transaction-pragmatic",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/common/transaction-pragmatic"),
    },
    {
        path: "/api/common/transaction/evolution",
        name: "api-common-transaction-evolution",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/common/transaction-evolution"),
    },
    // transfer api
    {
        path: "/api/transfer/introduce",
        name: "api-transfer-introduce",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/transfer/introduce"),
    },
    {
        path: "/api/transfer/register",
        name: "api-transfer-register",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/transfer/register"),
    },
    {
        path: "/api/transfer/play",
        name: "api-transfer-play",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/transfer/play"),
    },
    {
        path: "/api/transfer/user/balance",
        name: "api-transfer-user-balance",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/transfer/user-balance"),
    },
    {
        path: "/api/transfer/user/balance/callback",
        name: "api-transfer-user-balance-callback",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/transfer/user-balance-callback"),
    },
    {
        path: "/api/transfer/deposit",
        name: "api-transfer-deposit",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/transfer/deposit"),
    },
    {
        path: "/api/transfer/withdraw",
        name: "api-transfer-withdraw",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/transfer/withdraw"),
    },
    {
        path: "/api/common/transactions/evolution",
        name: "api-common-transactions-evolution",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/transfer/transactions-evolution"),
    },
    // seamlsess api
    {
        path: "/api/seamless/introduce",
        name: "api-seamless-introduce",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/seamless/introduce"),
    },
    {
        path: "/api/seamless/authenticate",
        name: "api-seamless-authenticate",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/seamless/authenticate"),
    },
    {
        path: "/api/seamless/balance",
        name: "api-seamless-balance",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/seamless/balance"),
    },
    {
        path: "/api/seamless/bet",
        name: "api-seamless-bet",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/seamless/bet"),
    },
    {
        path: "/api/seamless/result",
        name: "api-seamless-result",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/seamless/result"),
    },
    {
        path: "/api/seamless/cancel",
        name: "api-seamless-cancel",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/seamless/cancel"),
    },
    {
        path: "/api/seamless/check",
        name: "api-seamless-check",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/api/seamless/check"),
    },
];
