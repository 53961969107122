import APIClient from '@/libraries/api-client'
// import store from '../store'

class Partner extends APIClient {
    async list(params) {
        return await this.post('partner', params)
    }

    async create(params) {
        return await this.post('partner/register', params)
    }

    async update(id, params) {
        return await this.post(`partner/update/${id}`, params)
    }

    async adjustVendors() {
        return await this.post('partner/vendors/adjust', {})
    }

    async children(parent) {
        return await this.post('partner/children', { parent })
    }

    async countChildren() {
        return await this.get('partner/children/count', { })
    }
}

export default Partner
