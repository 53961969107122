import UserAPI from './user'
import PartnerAPI from './partner'
import StatisticAPI from './statistic'
import GameAPI from './game'
import LogAPI from './log'
import SettingAPI from './setting'
import TransactionAPI from './transaction'
import AdminAPI from './admin'
import BoardAPI from './board'

export default {
    install: (app) => {
        app.config.globalProperties.$API = {
            user: new UserAPI(),
            statistic: new StatisticAPI(),
            partner: new PartnerAPI(),
            game: new GameAPI(),
            log: new LogAPI(),
            setting: new SettingAPI(),
            transaction: new TransactionAPI(),
            admin: new AdminAPI(),
            board: new BoardAPI()
        }
    }
}
