export default [
    {
        path: "/error/404",
        name: "error-404",
        meta: { authRequired: true },
        component: () => import("../../views/error/404"),
    },
    {
        path: "/error/500",
        name: "error-500",
        meta: { authRequired: true },
        component: () => import("../../views/error/500"),
    }
];
