import APIClient from '@/libraries/api-client'

class Log extends APIClient {

    async seamless(params) {
        return await this.post('log/seamless', params)
    }

    async activity(params) {
        return await this.post('log/activity', params)
    }

    async server(params) {
        return await this.post('log/server', params)
    }
}

export default Log
