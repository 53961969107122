import APIClient from '@/libraries/api-client'
import store from '../store'

class User extends APIClient {

    async login (username, password, captchaKey, captchaText) {
        return await this.post('auth/login', {username, password, captchaKey, captchaText})
    }

    async getUser(id) {
        return await this.get(`user/get/${id}`)
    }

    async logout() {
        store.commit('user/SET_CURRENT_USER', null)
        return {code: 0}
    }

    async forgetPassword(email) {
        return await this.post('auth/forgot/password', { email })
    }

    async getAuthUser(ct = 'y') {
        return await this.get('mypage/refresh?ct=' + ct)
    }

    async modifyInfo(id, params) {
        return await this.post(`user/modifyInfo/${id}`, params)
    }

    async getAvatar(username) {
        let result = await this.get(`user/avatar?seed=${username}`)
        return result
    }

    async getCaptcha() {
        let result = await this.get(`auth/captcha.svg?_t=${new Date().getTime()}`)
        return result
    }

    async getChildCashSum () {
        return await this.get('user/getChildCashSum')
    }

    async list(params) {
        return await this.post('user', params)
    }

    async saveInfo(params) {
        return await this.post('user/api/save', params)
    }

    async saveTransferCallbackUrl(params) {
        return await this.post('user/api/transfer/save', params)
    }

    async saveSeamlessCallbackUrl(params) {
        return await this.post('user/api/seamless/save', params)
    }

    async saveDetailCallbackUrl(params) {
        return await this.post('user/api/detail/save', params)
    }

    async deposit (params) {
        return await this.post('user/cash/add', params)
    }

    async withdraw (params) {
        return await this.post('user/cash/subtract', params)
    }

    async delete(id) {
        if (!id) {
            return { code: -1, msg: 'CHECK USER ID' }
        }
        return await this.post('user/delete/' + id, {})
    }

    async domain() {
        return await this.get('domain/read')
    }

    async saveDomain(form) {
        return await this.send('domain/update', form)
    }

    async changeState(id, state) {
        return await this.post(`user/state/change/${id}/${state}`, {id, state})
    }
}

export default User
