export default [
    {
        path: "/setting/api",
        name: "setting-api",
        meta: { authRequired: true },
        component: () => import("../../views/setting/api"),
    },
    {
        path: "/setting/companies",
        name: "setting-companies",
        meta: { authRequired: true },
        component: () => import("../../views/setting/companies"),
    },
    {
        path: "/setting/vendors",
        name: "setting-vendors",
        meta: { authRequired: true },
        component: () => import("../../views/setting/vendors"),
    },
    {
        path: "/setting/games",
        name: "setting-games",
        meta: { authRequired: true },
        component: () => import("../../views/setting/games"),
    },
    {
        path: "/setting/maintenance",
        name: "setting-maintenance",
        meta: { authRequired: true },
        component: () => import("../../views/setting/maintenance"),
    },
    {
        path: "/setting/notice",
        name: "setting-notice",
        meta: { authRequired: true },
        component: () => import("../../views/setting/notice"),
    },
    {
        path: "/setting/update",
        name: "setting-update",
        meta: { authRequired: true },
        component: () => import("../../views/setting/update"),
    },
    {
        path: "/setting/vendor/update",
        name: "setting-vendor-update",
        meta: { authRequired: true },
        component: () => import("../../views/setting/vendor-update"),
    },
    {
        path: "/setting/cash",
        name: "setting-cash",
        meta: { authRequired: true },
        component: () => import("../../views/setting/cash"),
    },
    {
        path: "/setting/log/server",
        name: "setting-server-log",
        meta: { authRequired: true },
        component: () => import("../../views/setting/server-log"),
    },
    {
        path: "/setting/status/server",
        name: "setting-server-status",
        meta: { authRequired: true },
        component: () => import("../../views/setting/server-status"),
    },
];
