export default [
    {
        path: "/partner/tree",
        name: "partner-tree",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/partner/tree"),
    },
    {
        path: "/partner/level/:level",
        name: "partner-level",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/partner/level"),
    },
    {
        path: "/partner/state/:state",
        name: "partner-state",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/partner/state"),
    },
    {
        path: "/partner/detail/:id",
        name: "partner-detail",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/partner/detail"),
    },
];
