import APIClient from '@/libraries/api-client'

class Setting extends APIClient {

    async getSetting(category, key) {
        return await this.get(`setting/${category}/${key}`)
    }

    async updateSetting(category, key, data) {
        return await this.post(`setting/${category}/${key}`, data)
    }

    async companies(params) {
        return await this.post('company', params)
    }

    async servers(params) {
        return await this.post('server', params)
    }

    async vendors(params) {
        return await this.post('vendor', params)
    }

    async games(params) {
        return await this.post('game', params)
    }

    async categories() {
        return await this.post('gameCategory', {})
    }

    async save(vendor) {
        return await this.post('company/save/' + vendor._id, vendor)
    }

    async updateVendor(id, vendor) {
        return await this.post('vendor/update/' + id, vendor)
    }

    async updateGame(id, game) {
        return await this.post('game/update/' + id, game)
    }

    async saveCompanyActive(id, active) {
        return await this.post('company/save/active/' + id, { active })
    }

    async saveCompanyMaintenance(id, maintenance) {
        return await this.post('company/save/maintenance/' + id, { maintenance })
    }
}

export default Setting
