export default [
    {
        path: "/user/state/:state",
        name: "user-state",
        meta: { authRequired: true },
        component: () => import("../../views/user/state"),
    },
    {
        path: "/user/detail/:id",
        name: "user-detail",
        meta: { authRequired: true },
        component: () => import("../../views/user/detail"),
    },
];
