let layoutType = localStorage.getItem('layoutType')
let layoutWidth = localStorage.getItem('layoutWidth')
let leftSidebarType = localStorage.getItem('leftSidebarType')
let topbar = localStorage.getItem('topbar')
let loader = localStorage.getItem('loader')
loader = false

export const state = {
  layoutType: layoutType ? layoutType : 'vertical',
  layoutWidth: layoutWidth ? layoutWidth : 'fluid',
  leftSidebarType: leftSidebarType ? leftSidebarType : 'dark',
  topbar: topbar ? topbar : 'dark',
  loader: (loader === 'true' || loader === true) ? true : false,
  logoImage: '',
  logoImageSmall: ''
}

export const getters = {
  logoImage(state) {
    return state.logoImage
  },
  logoImageSmall(state) {
    return state.logoImageSmall
  },
}

export const mutations = {
  CHANGE_LOGO_IMAGE(state, image) {
    state.logoImage = image
  },
  CHANGE_LOGO_IMAGE_SMALL(state, image) {
    state.logoImageSmall = image
  },
  CHANGE_LAYOUT(state, layoutType) {
    state.layoutType = layoutType;
    localStorage.setItem('layoutType', layoutType)
  },
  CHANGE_LAYOUT_WIDTH(state, layoutWidth) {
    state.layoutWidth = layoutWidth;
    localStorage.setItem('layoutWidth', layoutWidth)
  },
  CHANGE_LEFT_SIDEBAR_TYPE(state, leftSidebarType) {
    state.leftSidebarType = leftSidebarType;
    localStorage.setItem('leftSidebarType', leftSidebarType)
  },
  CHANGE_TOPBAR(state, topbar) {
    state.topbar = topbar;
    localStorage.setItem('topbar', topbar)
  },
  LOADER(state, loader) {
    state.loader = loader
    localStorage.setItem('loader', loader)
  }
}

export const actions = {
  changeLayoutType({ commit }, { layoutType }) {
    commit('CHANGE_LAYOUT', layoutType);
  },

  changeLayoutWidth({ commit }, { layoutWidth }) {
    commit('CHANGE_LAYOUT_WIDTH', layoutWidth)
  },

  changeLeftSidebarType({ commit }, { leftSidebarType }) {
    commit('CHANGE_LEFT_SIDEBAR_TYPE', leftSidebarType)
  },

  changeTopbar({ commit }, { topbar }) {
    commit('CHANGE_TOPBAR', topbar)
  },

  changeLoaderValue({ commit }, { loader }) {
    commit('LOADER', loader)
  }
}
