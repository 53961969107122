import Account from '@/router/modules/account'
import Setting from '@/router/modules/setting'
import User from '@/router/modules/user'
import Utility from '@/router/modules/utility'
import Error from '@/router/modules/error'
import Dashboard from "@/router/modules/dashboard"
import Report from "@/router/modules/report"
import Transaction from "@/router/modules/transaction"
import Admin from "@/router/modules/admin"
import Partner from "@/router/modules/partner"
import Api from "@/router/modules/api"

export default [
  ...Dashboard,

  // 미인증 페이지들
  ...Account,

  // 인증 페이지들
  ...Setting,
  ...User,
  ...Utility,
  ...Report,
  ...Transaction,
  ...Admin,
  ...Partner,
  ...Api,

  ...Error,

  // 오류페이지(404)
  {
    path: "/:catchAll(.*)",
    redirect: "/error/404"
  }
];