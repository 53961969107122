import UserAPI from '../../services/user'
const userAPI = new UserAPI()

const cookiePrefix = process.env.VUE_APP_COOKIE_PREFIX
let user = localStorage.getItem(`${cookiePrefix}.auth.user`)

if (user) {
    try {
        user = JSON.parse(user)
    } catch(e) {
        window.localStorage.removeItem(`${cookiePrefix}.auth.user`)
        user = null
    }
}

export const state = {
    currentUser: user,
    validateErrorCount: 0
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState(`${cookiePrefix}.auth.user`, newValue)
    },
    SET_VALIDATE_ERROR_COUNT(state, newValue) {
        state.validateErrorCount = newValue
    },
    SET_CASH(state, newValue) {
        state.currentUser.cash = newValue
    },
    SET_TOKEN(state, token) {
        state.currentUser.token = token
    }
}

export const getters = {
    valid(state) {
        return !!state.currentUser
    },
    user(state) {
        return state.currentUser
    },
    token(state) {
        return state.currentUser ? state.currentUser.token : null
    },
    validateErrorCount(state) {
        return state.validateErrorCount
    }
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    logout({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
    },

    /**
     * 일정한 시간동안 액션이 없으면 로그아웃된다.
     * @param commit
     * @param dispatch
     * @param getters
     */
    // eslint-disable-next-line no-unused-vars
    async refresh({ commit, dispatch, getters }) {
         if (getters.valid) {
            await dispatch('validate', {ct: 'y'})
            setInterval(async function() {
                await dispatch('validate', {ct: 'y'})
            }, 60000 * 2) //2분
        }
    },

    // eslint-disable-next-line no-unused-vars
    async resetPassword({ commit, dispatch, getters }, { username } = {}) {
        if (getters.valid) return await dispatch('validate')
    },

    // eslint-disable-next-line no-unused-vars
    async validate({ commit, state }, payload) {
        if (!state.currentUser) return Promise.resolve(null)
        const result = await userAPI.getAuthUser(payload.ct ? payload.ct : 'n')
        if (result.code !== 0) {
            if (result.code === -1000) {
                alert(result.msg)
                commit('SET_CURRENT_USER', null)
                document.location.reload()
                return
            } else {
                commit('SET_VALIDATE_ERROR_COUNT', state.validateErrorCount + 1)
                if (state.validateErrorCount >= 5) {
                    commit('SET_CURRENT_USER', null)
                    alert('Your network connection is not stable..Fail : ' + state.validateErrorCount)
                    document.location.reload()
                    return
                }
                return Promise.resolve(null)
            }
        }

        commit('SET_VALIDATE_ERROR_COUNT', 0)
        commit('SET_CURRENT_USER', result.user)

        saveState(`${cookiePrefix}.auth.user`, result.user)
        return result.user
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
