import { partial } from 'filesize'
const filesize = partial({base: 2, standard: "jedec"})

String.prototype.string = function (len) { var s = ''; var i = 0; while (i++ < len) { s += this } return s }
String.prototype.zf = function (len) { return '0'.string(len - this.length) + this }
Number.prototype.zf = function (len) { return this.toString().zf(len) }
String.prototype.capitalize = function () { return this.charAt(0).toUpperCase() + this.slice(1) }
String.prototype.toHex = function () {
    var arr = []
    for (var i = 0, l = this.length; i < l; i++) {
        var hex = Number(this.charCodeAt(i)).toString(16)
        arr.push(hex)
    }
    return arr.join('')
}

String.prototype.toAscii = function () {
    var hex = this.toString()// force conversion
    var str = ''
    for (var i = 0; i < hex.length; i += 2) { str += String.fromCharCode(parseInt(hex.substr(i, 2), 16)) }
    return str
}

Date.prototype.format = function (f) {
    if (!this.valueOf()) return ' '

    const weekName = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
    const d = this

    return f.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|zzz|a\/p)/gi, function ($1) {
        let h
        switch ($1) {
            case 'YYYY': return d.getFullYear()
            case 'YY': return (d.getFullYear() % 1000).zf(2)
            case 'MM': return (d.getMonth() + 1).zf(2)
            case 'DD': return d.getDate().zf(2)
            case 'E': return weekName[d.getDay()]
            case 'HH': return d.getHours().zf(2)
            case 'hh': {
                h = d.getHours() % 12
                return (h ? h : 12).zf(2)
            }
            case 'mm': return d.getMinutes().zf(2)
            case 'ss': return d.getSeconds().zf(2)
            case 'zzz': return d.getMilliseconds().zf(3)
            case 'a/p': return d.getHours() < 12 ? '오전' : '오후'
            default: return $1
        }
    })
}

Date.prototype.formatUTC = function (f) {
    if (!this.valueOf()) return ' '

    var weekName = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일']
    var d = this

    return f.replace(/(yyyy|yy|MM|dd|E|hh|mm|ss|zzz|a\/p)/gi, function ($1) {
        let h
        switch ($1) {
            case 'YYYY': return d.getUTCFullYear()
            case 'YY': return (d.getUTCFullYear() % 1000).zf(2)
            case 'MM': return (d.getUTCMonth() + 1).zf(2)
            case 'DD': return d.getUTCDate().zf(2)
            case 'E': return weekName[d.getUTCDay()]
            case 'HH': return d.getUTCHours().zf(2)
            case 'hh': {
                h = d.getUTCHours() % 12
                return (h ? h : 12).zf(2)
            }
            case 'mm': return d.getUTCMinutes().zf(2)
            case 'ss': return d.getUTCSeconds().zf(2)
            case 'zzz': return d.getUTCMilliseconds().zf(3)
            case 'a/p': return d.getUTCHours() < 12 ? '오전' : '오후'
            default: return $1
        }
    })
}

Number.prototype.formatThousands = function () {
    return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

String.prototype.formatThousands = function () {
    return this.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

Number.prototype.floatVal = function (len = 2) {
    return parseFloat(Number(this).toFixed(len))
}

String.prototype.floatVal = function () {
    return parseFloat(Number(this).toFixed(2))
}

String.prototype.format = function () {
    var args = arguments
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] !== 'undefined'
            ? args[number]
            : match
    })
}

String.prototype.filesize = function() {
    return filesize(parseFloat(this))
}

Number.prototype.filesize = function() {
    return filesize(this)
}
