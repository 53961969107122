import { createWebHistory, createRouter } from "vue-router";

import store from '@/store'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

  // If auth isn't required for the route, just continue.
  if (!authRequired) {
    if (routeTo.path === '/login') {
      if (store.getters['auth/valid']) {
        return next({ name: 'dashboard' })
      }
    }
    return next()
  }

  // If auth is required and the user is logged in...
  if (store.getters['auth/valid']) {
    // 유저데이터가 존재하는지 확인
    return store.dispatch('auth/validate', {ct: 'n'}).then((validUser) => {
      if (validUser === false) {
        //validUser ? next() : redirectToLogin()
        return redirectToLogin()
      } else {
        return next()
      }
    })
  }

  redirectToLogin()

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-inner-declarations
  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }
})

export default router
