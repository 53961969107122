export default [
    {
        path: "/admin/state/:state",
        name: "admin-state",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/admin/state"),
    },
    {
        path: "/admin/level/:level",
        name: "admin-level",
        meta: {
            authRequired: true,
        },
        component: () => import("../../views/admin/level"),
    },
];
