import APIClient from '@/libraries/api-client'

class Admin extends APIClient {
    async list(params) {
        return await this.post('admin', params)
    }

    async create(params) {
        return await this.post('admin/register', params)
    }

    async update(id, params) {
        return await this.post(`admin/update/${id}`, params)
    }
}

export default Admin
