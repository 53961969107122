import APIClient from '@/libraries/api-client'
import striptags from 'striptags'

class User extends APIClient {
    async list(key, params) {
        if (!key) {
            return { code: -1, msg: 'CHECK BOARD KEY' }
        }

        return await this.post(`document/${key}`, params)
    }

    async create(key, params) {
        if (!params.title) {
            return { code: -1, msg: 'CHECK TITLE' }
        }

        const content = striptags(params.content).trim()
        if (!content) {
            return { code: -1, msg: 'CHECK CONTENT' }
        }

        return await this.post(`document/create/${key}`, params)
    }

    async read(key, id) {
        if (!key) {
            return { code: -1, msg: 'CHECK BOARD KEY' }
        }

        if (!id) {
            return { code: -1, msg: 'CHECK DOCUMENT ID' }
        }
        return await this.post(`document/read/${key}/${id}`, {})
    }

    async delete(id) {
        if (!id) {
            return { code: -1, msg: 'CHECK DOCUMENT ID' }
        }
        return await this.post(`document/delete/${id}`, {})
    }

    async update(id, params) {
        if (!id) {
            return { code: -1, msg: 'CHECK DOCUMENT ID' }
        }
        return await this.post(`document/update/${id}`, params)
    }
}

export default User
