import {Container} from 'typedi'

export default class TMutex {
  list

  constructor () {
    this.list = {}
  }

  async acquire(flag = '') {
    if (! this.list.flag) {
      this.list[flag] = []
    }

    let key = 0
    if (this.list[flag].length > 0) {
      key = parseInt(this.list[flag][this.list[flag].length-1].key) + 1
    }

    this.list[flag].push({key})

    let keyIndex = -1
    while( keyIndex !== 0 ) {
      for (let idx in this.list[flag]) {
        let d = this.list[flag][idx]
        if (d.key === key) {
          keyIndex = parseInt(idx)
        }
      }
      if (keyIndex !== 0) {
        await this.wait(1)
      }
    }

    return new Promise(function(resolve) {
      resolve(key)
    })
  }

  async release(flag = '', key) {
    if (this.list.flag) {
      for(let idx in this.list[flag]) {
        let d = this.list[flag][idx]
        if (d.key === key) {
          this.list[flag].splice(idx, 1)
          break
        }
      }

      if (this.list[flag].length === 0) {
        delete this.list[flag]
      }
    }
    return new Promise(function(resolve) {
      resolve()
    })
  }

  async wait(ms = 0) {
    return new Promise(function (resolve) {
      setTimeout(resolve, ms);
    });
  }
}

Container.set('Mutex', new TMutex())
